@import-normalize; /* bring in normalize.css styles */

@font-face {
  font-family: "SourceSansPro";
  src: local("SourceSansPro"),
    url(./fonts/SourceSansPro-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "SourceSansProSemiBold";
  src: local("SourceSansProSemiBold"),
    url(./fonts/SourceSansPro-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "SourceSansProBold";
  src: local("SourceSansProBold"),
    url(./fonts/SourceSansPro-Bold.ttf) format("truetype");
}

* {
  box-sizing: border-box;
  font-size: 18px;
}

/* Login */

input,
input:focus {
  outline: none;
}

a {
  text-decoration: none;
}

.clickable {
  cursor: pointer;
}

.hidden {
  display: none;
}

.link {
  color: #77bd22;
  cursor: pointer;
}

.link--newToIspot {
  color: #979b9e;
}

.text--center {
  text-align: center;
}

.text--light {
  color: #9a9ea1;
}

.text--dark {
  color: #515a5f;
}

.text--red {
  color: #e95136;
}

.text--blue {
  color: #199cb7;
}

.text--white {
  color: #fff;
}

.text--semibold {
  font-family: "SourceSansProSemiBold";
}

.back {
  text-align: left;
}

.container {
  padding: 3rem;
  text-align: center;
}

.app {
  color: #fff;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  padding: 3rem;
  text-align: center;
  width: 100%;
}

.landingPage {
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow: auto;
  padding: 140px 6rem 50px;
}

.landingPage__content {
  display: flex;
  justify-content: flex-end;
  color: #fff;
  font-family: "SourceSansPro", sans-serif;
  text-align: right;
}

.landingPage__adTitle {
  color: rgba(255, 255, 255, 0.4);
  font-family: "SourceSansProSemiBold", sans-serif;
}

.landingPage__text {
  margin: 30px 3rem;
  max-width: 260px;
}

.landingPage h1 {
  color: #77bd22;
  font-size: 26px;
}

.btn {
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  height: 50px;
  padding: 0.375rem 0.75rem;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
}

.form-control {
  background: #fff;
  border: 1px solid #d5d5d5;
  border-radius: 0.25rem;
  color: #495057;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  height: 50px;
  padding: 0.375rem 0.75rem;
  width: 100%;
}

.form-control--error {
  background: #fef8f7;
  border-color: #e95136;
}

.form-control--focus {
  border-color: #199cb7;
}

.error {
  color: #e95136;
  font-family: "SourceSansProSemiBold", sans-serif;
}

.modal {
  padding: 40px 60px;
}

.modal__title {
  font-family: "SourceSansProSemiBold", sans-serif;
  font-size: 22px;
  text-align: center;
}

.modal__form {
  margin: 40px auto;
}

.modal__form > div {
  margin-bottom: 20px;
}

.modal__form div span {
  overflow-wrap: break-word;
}

.modal__form div span:first-child {
  font-family: "SourceSansProSemiBold", sans-serif;
}

.modal__buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
}

.loader {
  text-align: center;
}

.card {
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 600px;
  max-width: 420px;
  min-width: 300px;
  text-align: left;
  width: auto;
}

.card__top {
  color: #515a5f;
  padding: 40px 3rem;
}

.card__logo {
  margin-bottom: 30px;
  width: 70%;
}

.card__bottom {
  background: #f9f8f8;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  margin-top: auto;
  padding: 30px 3rem;
}

.login__form {
  margin-bottom: 15px;
}

.login__redirect {
  margin-top: 50px;
}

.login__title {
  font-family: "SourceSansProSemiBold", sans-serif;
}

.login__title span {
  font-family: "SourceSansProBold", sans-serif;
}

.login__btn {
  background: #77bd22;
  border: #67a817;
  color: white;
  font-family: "SourceSansProSemiBold", sans-serif;
  width: 100%;
}

.login__input {
  margin-bottom: 1rem;
}

.password_rules {
  margin-bottom: 20px;
}

.password_rules p {
  margin: 0;
}

/* Admin */

.dashboard {
  padding: 20px 3rem;
  text-align: center;
}

.header {
  background: #3f51b5;
  color: #fff;
  padding: 15px 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header img {
  width: 100px;
}

.header__link {
  color: #515a5f;
  padding: 0 10px;
}

.dashboard__nav {
  display: flex;
  justify-content: space-between;
}

.dashboard__btns {
  margin: 3rem;
}

.MuiDataGrid-row {
  cursor: pointer;
}

.MuiSelect-select.MuiSelect-select {
  text-align: left;
}

.admin__form {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 400px;
  margin: auto;
}

.admin__form__checkbox {
  margin-top: 20px;
  text-align: left;
}

.admin__form__submit {
  margin: 20px auto;
  width: 100%;
}

.sso__title {
  margin: 20px auto;
}

.sso__domain {
  margin: 40px auto;
}

.domain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.domain__title {
  margin: 50px auto 25px;
}

.domain__buttons button {
  margin: 5px 10px;
}

@media (max-width: 1024px) {
  .landingPage {
    padding: 50px 2rem;
    align-items: center;
  }
  .landingPage__content {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .landingPage__text {
    max-width: 600px;
    text-align: center;
  }
}

@media (max-width: 538px) {
  .landingPage {
    padding: 50px 1rem;
    align-items: center;
  }
  .landingPage__text,
  .landingPage__adTitle {
    display: none;
  }
}

@media (max-width: 400px) {
  .card__top {
    padding: 40px 1rem;
  }
}
